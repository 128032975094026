import api from './api.js';

const TimeArr: object[] = [];
for (let i = 0; i < 24; i++) {
  TimeArr.push({ text: i + '时', value: `${i}` });
}
const adminConfig = {
  otherConfiguration: {
    apiPrefixPath: api.apiPrefixPath,
    /* 默认线上请求地址 */
    uploadExcelUrl: '/customer/uploadExcel', // excel上传地址
    pictureUploading: '/upload/img', // 图片上传地址
    picturePrefix: 'image', // 图片前缀
  },
  requestParameters: {
    // 请求参数
    request_SUCCESS: 0, // 请求成功状态
    request_FAIL: 1, // 请求失败状态
    request_TOKEN_ERROR: 401, // 登录失效
    request_SIGN_ERROR: 402, // 签名校验失败
    request_AUTH_ERROR: 403, // 访问受限
    request_RUN_EXCEPTION: -1, // 主动抛出异常（一般为参数校验失败，或流程校验不通过等）
    request_BLACK: -10, // 黑名单
    request_NOT_PERMISSION: -20, // 无权限访问
    request_TIMEOUT: -30, // 请求超时
    request_VISIT_OUT: -40, // 访问次数受限
    request_NOT_FOUND: 404, // 资源不存在
  },
  store: {
    authorCacheName: 'userLoginInfo',
  },
  componentName: {
    MyTitle: 'MyTitle',
    MyInput: 'MyInput',
    MySelect: 'MySelect',
    MySelectCity: 'MySelectCity',
    MySelectCityLocal: 'MySelectCityLocal',
    MyCityLocalSearch: 'MyCityLocalSearch',
    MyTreeSelect: 'MyTreeSelect',
    MyRadio: 'MyRadio',
    MyCheckbox: 'MyCheckbox',
    ImgUpload: 'ImgUpload',
    MyTime: 'MyTime',
    MyDateTime: 'MyDateTime',
    MyRadioTime: 'MyRadioTime',
    MyCustomFormItem: 'MyCustomFormItem',
    MyGuideConfig: 'MyGuideConfig',
    MyText: 'MyText',
    MyCustomAddInputInputItem: 'MyCustomAddInputInputItem',
    MyInputLinkInputItem: 'MyInputLinkInputItem',
  },
  table: {
    buttonType: {
      // table按钮功能
      otherEvents: 'otherEvents', // 按钮其他事件
      delete: 'Delete', // 删除功能按钮
      render: 'Render', // 自定义内容按钮
    },
  },
  commonList: {
    banner: [
      { text: 'banner', value: 1 },
      { text: '运营弹窗', value: 2 },
      { text: '橱窗', value: 3 },
    ],
    callbackWay: [
      { text: '不回调', value: 0 },
      { text: '回调', value: 1 },
    ],
    callBackType: [
      {
        text: '不回传',
        value: 0,
      },
      {
        text: '腾讯',
        value: 1,
      },
      {
        text: '快手',
        value: 2,
      },
      {
        text: '微博',
        value: 3,
      },
      {
        text: 'B站',
        value: 4,
      },
      {
        text: '百度',
        value: 5,
      },
      {
        text: '优酷',
        value: 6,
      },
      {
        text: '美柚',
        value: 7,
      },
      {
        text: '知乎',
        value: 8,
      },
      {
        text: '搜狐',
        value: 9,
      },
      {
        text: '爱奇艺',
        value: 11,
      },
      {
        text: '360',
        value: 12,
      },
      {
        text: '流量果',
        value: 13,
      },
    ],
    moreRecommend_: [
      { text: '否', value: 0 },
      { text: '是', value: 1 },
    ],
    jumpType: [
      { text: '内链', value: 1 },
      { text: '外链', value: 2 },
    ],
    keepOutType: [
      { text: '不限', value: 0 },
      { text: '允许推送', value: 1 },
      { text: '禁止推送', value: 2 },
    ],
    jumpTypeMore: [
      { text: '外链', value: 1 },
      { text: '产品', value: 2 },
    ],
    productType: [
      { text: '网贷产品', value: 1 },
      { text: '助贷产品', value: 2 },
    ],
    processList: [
      { text: 'H5产品', value: 1, disabled: false },
      { text: '联登产品', value: 2, disabled: false },
      { text: '信贷api', value: 3, disabled: false },
      // { text: '网贷api', value: 4, disabled: false },
    ],
    isMobileSupport: [
      { text: '安卓', value: 'android' },
      { text: '苹果', value: 'ios' },
    ],
    applicationStatus: [
      { text: '申请成功', value: 1 },
      { text: '申请失败', value: 2 },
    ],
    // 渠道列表-状态
    channelStatus_: [
      {
        text: '下线',
        value: 0,
      },
      {
        text: '上线',
        value: 1,
      },
    ],
    // 渠道域名
    channelDomainName: [
      {
        text: '阿里云',
        value: 0,
      },
      {
        text: 'top云',
        value: 1,
      },
    ],
    // 显示状态
    ifHideList: [
      {
        text: '未隐藏',
        value: 0,
      },
      {
        text: '已隐藏',
        value: 1,
      },
    ],
    domainList: [
      {
        text: '留资渠道页面访问地址',
        value: 10,
      },
      {
        text: '注册下载渠道页面访问地址',
        value: 11,
      },
      {
        text: 'OSS文件',
        value: 20,
      },
      {
        text: 'App接口地址',
        value: 30,
      },
      {
        text: 'Web接口地址',
        value: 40,
      },
      {
        text: '管理端接口访问地址',
        value: 50,
      },
      {
        text: 'wap页面访问地址',
        value: 60,
      },
    ],
    domainType: [
      {
        text: '留资渠道',
        value: 10,
      },
      {
        text: '注册下载渠道',
        value: 11,
      },
    ],
    // 渠道列表-add - 城市限定
    cityEnable: [
      { text: '准入城市', value: 1 },
      { text: '禁用城市', value: 2 },
    ],
    // 选择是否
    isControl: [
      {
        text: '否',
        value: 0,
      },
      {
        text: '是',
        value: 1,
      },
    ],
    agreementType: [
      {
        text: '静态协议',
        value: 1,
      },
      {
        text: '动态协议',
        value: 2,
      },
    ],
    onlineStatus: [
      {
        text: '正常',
        value: 0,
      },
      {
        text: '停用',
        value: 1,
      },
    ],
    sex: [
      {
        text: '男',
        value: 0,
      },
      {
        text: '女',
        value: 1,
      },
    ],
    osDisplay: [
      {
        text: '不限制',
        value: 0,
      },
      {
        text: '展示Android',
        value: 1,
      },
      {
        text: '展示iOS',
        value: 2,
      },
    ],
    osTypeDisplay: [
      {
        text: '不限制',
        value: 0,
      },
      {
        text: 'H5',
        value: 1,
      },
      {
        text: 'APP',
        value: 2,
      },
    ],
    openDetail: [
      {
        text: '不开启',
        value: 0,
      },
      {
        text: '开启',
        value: 1,
      },
    ],
    limitCityType: [
      {
        text: '不限',
        value: 0,
      },
      {
        text: '准入',
        value: 1,
      },
      {
        text: '禁入',
        value: 2,
      },
    ],
    protocolType: [
      {
        text: '展示我司协议',
        value: 1,
      },
      {
        text: '展示我司协议+产品协议',
        value: 2,
      },
    ],
    nullParam: [
      {
        text: '不展示',
        value: 0,
      },
      {
        text: '展示',
        value: 1,
      },
    ],
    allSatisfy: [
      {
        text: '选择条件全满足',
        value: 1,
      },
      {
        text: '选择条件单项满足',
        value: 2,
      },
    ],
    withdrawalWay: [
      {
        text: '三方H5提现',
        value: 1,
      },
      {
        text: '三方下载提现',
        value: 2,
      },
      {
        text: '站内提现',
        value: 3,
      },
    ],
    apiType: [
      {
        text: '半流程',
        value: 1,
      },
      {
        text: '全流程',
        value: 2,
      },
    ],
    accessType: [
      {
        text: '是',
        value: 1,
      },
      {
        text: '否',
        value: 2,
      },
    ],
    accessTypeList: [
      { text: '否', value: 1 },
      { text: '是', value: 2 },
    ],
    hoursList: TimeArr,
    recordType: [
      {
        text: '充值',
        value: 1,
      },
      {
        text: '退款',
        value: 2,
      },
      {
        text: '获客',
        value: 3,
      },
    ],
    carListType: [
      {
        text: '无车产',
        value: 1,
      },
      {
        text: '有车',
        value: 2,
      },
    ],
    houseListType: [
      {
        text: '无房产',
        value: 1,
      },
      {
        text: '有房',
        value: 2,
      },
    ],
    socialInsuranceListType: [
      {
        text: '无社保',
        value: 1,
      },
      {
        text: '缴纳未满6个月',
        value: 2,
      },
      {
        text: '缴纳6个月以上',
        value: 3,
      },
    ],
    gjjListType: [
      {
        text: '无公积金',
        value: 1,
      },
      {
        text: '缴纳未满6个月',
        value: 2,
      },
      {
        text: '缴纳6个月以上',
        value: 3,
      },
    ],
    insurancePolicyListType: [
      {
        text: '无保单',
        value: 1,
      },
      {
        text: '缴纳不足一年 ',
        value: 2,
      },
      {
        text: '缴纳1年以上',
        value: 3,
      },
      {
        text: '缴纳2年以上',
        value: 4,
      },
    ],
    sesameListType: [
      {
        text: '无芝麻分',
        value: 1,
      },
      {
        text: '600分以下 ',
        value: 2,
      },
      {
        text: '600~650分',
        value: 3,
      },
      {
        text: '650~700分',
        value: 4,
      },
      {
        text: '700分以上',
        value: 5,
      },
    ],
    payType: [
      {
        text: '手动',
        value: 0,
      },
      {
        text: '微信',
        value: 1,
      },
      {
        text: '支付宝',
        value: 2,
      },
    ],
    aidCategory: [
      {
        text: '房主贷',
        value: 1,
      },
      {
        text: '车主贷',
        value: 2,
      },
      {
        text: '公积金贷',
        value: 3,
      },
      {
        text: '保单贷',
        value: 4,
      },
      {
        text: '信用贷',
        value: 5,
      },
    ],
    shelfCycle: [
      {
        text: '每日上线',
        value: 1,
      },
      {
        text: '工作日上线（周一到周五）',
        value: 2,
      },
      {
        text: '周六周末上线',
        value: 3,
      },
      {
        text: '周六上线',
        value: 4,
      },
      {
        text: '周末上线',
        value: 5,
      },
    ],
    weekList: [
      {
        text: '周一',
        value: 1,
      },
      {
        text: '周二',
        value: 2,
      },
      {
        text: '周三',
        value: 3,
      },
      {
        text: '周四',
        value: 4,
      },
      {
        text: '周五',
        value: 5,
      },
      {
        text: '周六',
        value: 6,
      },
      {
        text: '周天',
        value: 7,
      },
    ],
    bannerList: [
      {
        text: '首页',
        value: 1,
      },
      {
        text: '个人中心上',
        value: 2,
      },
    ],
    operationList: [
      {
        text: '首页',
        value: 1,
      },
      {
        text: '个人中心',
        value: 2,
      },
    ],
    shopWindowList: [
      {
        text: '个人中心弹窗',
        value: 1,
      },
      {
        text: 'app退出拦截弹窗',
        value: 2,
      },
    ],
    implType: [
      {
        text: '阿里云短信',
        value: 1,
      },
      {
        text: '极光push实现',
        value: 2,
      },
      {
        text: '邮件实现',
        value: 3,
      },
    ],
    keyList: [
      { text: '埋点', value: 1 },
      { text: '参数', value: 2 },
      { text: '非去重表列', value: 3 },
    ],
    auditStatusList: [
      { text: '待提审', value: 0 },
      { text: '审核中', value: 1 },
      { text: '审核成功', value: 2 },
      { text: '审核失败', value: 3 },
    ],
    authFlow: [
      { text: '实名认证', value: 1, disabled: true },
      { text: '联系人认证', value: 2 },
      { text: '补充信息认证', value: 3 },
      { text: '银行卡认证', value: 4 },
      { text: '第三方自定义认证', value: 5 },
    ],
    isBankType: [
      { text: '静态银行列表', value: 1 },
      { text: '动态银行列表', value: 2 },
    ],
    applySource: [
      { text: 'APP个人中心返回拦截弹窗', value: 'app_user_callback' },
      { text: 'APP返回拦截弹窗', value: 'app_callback' },
      { text: '留资返回弹窗', value: 'app_remain_callback' },
      { text: '产品页返回弹窗', value: 'app_product_detail_callback' },
    ],
    isOpen: [
      {
        text: '关闭',
        value: 0,
      },
      {
        text: '开启',
        value: 1,
      },
    ],
    withdrawalPage: [
      {
        text: '关闭',
        value: 0,
      },
      {
        text: '动态',
        value: 1,
      },
      {
        text: '静态',
        value: 2,
      },
    ],
    bindCard: [
      {
        text: '关闭',
        value: 0,
      },
      {
        text: '站内绑卡',
        value: 1,
      },
      {
        text: '三方页面绑卡',
        value: 3,
      },
    ],
    loanType: [
      {
        text: '首贷',
        value: 1,
      },
      {
        text: '复贷',
        value: 2,
      },
    ],
    apiOrderStatus: [
      {
        text: '提交申请',
        value: 0,
      },
      {
        text: '进件成功',
        value: 1,
      },
      {
        text: '进件失败',
        value: 2,
      },
      {
        text: '授信成功',
        value: 3,
      },
      {
        text: '授信失败',
        value: 4,
      },
      {
        text: '提现成功',
        value: 5,
      },
      {
        text: '提现失败',
        value: 6,
      },
      {
        text: '放款成功',
        value: 7,
      },
      {
        text: '放款失败',
        value: 8,
      },
      {
        text: '已结清',
        value: 9,
      },
      {
        text: '取消订单',
        value: 10,
      },
      {
        text: '紧急划回',
        value: 11,
      },
      {
        text: '额度失效',
        value: 30,
      },
      {
        text: '逾期',
        value: 31,
      },
      {
        text: '正常还款中',
        value: 32,
      },
    ],
    showType: [
      {
        text: '每次展示',
        value: 1,
      },
      {
        text: '每天展示一次',
        value: 2,
      },
    ],
    categorysList: [
      {
        text: 'H5产品',
        value: 1,
      },
      {
        text: '联登产品',
        value: 2,
      },
      {
        text: '信贷API产品',
        value: 3,
      },
    ],
    loginJumpType: [
      {
        text: '结果页',
        value: 1,
      },
      {
        text: '下载app',
        value: 2,
      },
    ],
    returnType: [
      {
        text: 'H5登录总',
        value: 1,
      },
      {
        text: 'H5登录新',
        value: 2,
      },
      {
        text: '留资提交人数',
        value: 1,
      },
      {
        text: '申请UV',
        value: 2,
      },
      {
        text: '联登成功数',
        value: 1,
      },
    ],
    creditApiAuthType: [
      {
        text: '三方URL',
        value: 1,
      },
      {
        text: '我司页面授权',
        value: 2,
      },
    ],
    productConfigType: [
      {
        text: '默认配置',
        value: 0,
      },
      {
        text: '自定义配置',
        value: 1,
      },
    ],
    settlementMethod: [
      {
        text: 'CPA结算',
        value: 'CPA',
      },
      {
        text: 'CPS结算',
        value: 'CPS',
      },
      {
        text: 'UV结算',
        value: 'UV',
      },
      {
        text: '注册结算',
        value: 'RS',
      },
    ],
    dealType: [
      {
        text: '未解决',
        value: 0,
      },
      {
        text: '已解决',
        value: 1,
      },
    ],
    questionType: [
      {
        text: '费率问题',
        value: 0,
      },
      {
        text: 'App使用问题',
        value: 1,
      },
      {
        text: 'App闪退',
        value: 2,
      },
      {
        text: '绑卡问题',
        value: 3,
      },
      {
        text: '提现问题',
        value: 4,
      },
    ],
    shelfStatus: [
      //上架状态
      {
        text: '下架',
        value: 0,
      },
      {
        text: '上架',
        value: 1,
      },
    ],
    clientType: [
      //客户端类型
      {
        text: 'IOS',
        value: 0,
      },
      {
        text: '安卓',
        value: 1,
      },
    ],
  },
};

export default adminConfig;
